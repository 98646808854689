import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Divider, Space } from 'antd';
import propTypes from 'prop-types';

import '../RightImageSection/right.css';

// const { Paragraph } = Typography;
// Section component for loading images on the left text on the right
function LeftImageSection({ sectionValues }) {
  return (
    <section className="right-image-section">
      <Row justify="start" align="middle">
        <Col xs={24} md={24} lg={11} xl={10} span={10}>
          <section className="image-section">
            <Image
              src={sectionValues.imgUrl}
              alt="btb"
              className="image-style"
            />
          </section>
        </Col>
        <Col xs={24} md={24} lg={11} xl={10} span={10} offset={1}>
          <section className="text-section">
            <Space direction="vertical" size="middle">
              <p className="r-text">{sectionValues.text}</p>
              <Divider orientation="center" type="horizontal">
                <Link to="/about">LEARN MORE...</Link>
              </Divider>
            </Space>
          </section>
        </Col>
      </Row>
    </section>
  );
}

LeftImageSection.propTypes = {
  sectionValues: propTypes.objectOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
      imgUrl: propTypes.string.isRequired,
    }),
  ).isRequired,
};

export default LeftImageSection;
