import React from 'react';
import { Link } from 'react-router-dom';
import {
  // Typography,
  Button,
  Row,
  Col,
  // Card,
  Image,
  Divider,
  Space,
} from 'antd';
import propTypes from 'prop-types';
import { BOOK_LINK } from '../../data/data';
import './right.css';

// const { Paragraph } = Typography;
// Section component for loading images on the right text on left
function RightImageSection({ sectionValues }) {
  return (
    <section className="right-image-section">
      <Row justify="end" align="middle">
        <Col xs={24} md={24} lg={10} xl={10} span={10}>
          <section className="text-section">
            <Space direction="vertical" size="middle">
              <p className="r-text">{sectionValues.text}</p>
              <Divider orientation="center" type="horizontal">
                <Link to="/books">LEARN MORE...</Link>
              </Divider>
              <Button type="primary" shape="round" size="large">
                <Link to={BOOK_LINK} target="_blank">
                  GET THE BOOK
                </Link>
              </Button>
            </Space>
          </section>
        </Col>
        <Col xs={24} md={24} lg={10} xl={10} span={10} offset={2}>
          <section className="image-section">
            <Image
              src={sectionValues.imgUrl}
              alt="btb"
              className="image-style"
            />
          </section>
        </Col>
      </Row>
    </section>
  );
}

RightImageSection.propTypes = {
  sectionValues: propTypes.objectOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
      imgUrl: propTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RightImageSection;
