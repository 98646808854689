import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Divider } from 'antd';
import {
  LinkedinOutlined,
  FacebookOutlined,
  XOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import EmailForm from '../Forms/EmailForm';
import {
  LINKEDIN_PAGE,
  FACEBOOK_PAGE,
  INSTAGRAM_PAGE,
  X_PAGE,
} from '../../data/data';

const { Title, Paragraph } = Typography;

function Footer() {
  const [buttonLabel] = useState('SUBSCRIBE');
  return (
    <footer>
      <Row
        justify="space-around"
        align="middle"
        style={{ height: '90%', color: '#fff' }}>
        <Col xs={24} md={24} lg={8} xl={8} span={8}>
          <Space direction="vertical" size="large">
            <Title level={2} className="footer-text">
              EVELYNE MWAKINA
            </Title>
            <Space.Compact>
              <Divider className="footer-text">
                Want to receive news on new publishes and blogs?
              </Divider>
            </Space.Compact>
            <Space.Compact>
              <EmailForm label={buttonLabel} />
            </Space.Compact>
          </Space>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8} span={8}>
          <Title level={4} className="footer-text">
            NAVIGATION
          </Title>
          <br />
          <Space direction="horizontal" size="large">
            <Link to="/">HOME</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/books">BOOKS</Link>
            <Link to="/blog">BLOG</Link>
            <Link to="/contact">CONTACT</Link>
          </Space>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8} span={8}>
          <Title level={4} className="footer-text">
            SOCIALS
          </Title>
          <br />
          <Space direction="horizontal" size="large" className="footer-text">
            <a
              href={LINKEDIN_PAGE}
              target="_blank"
              rel="noreferrer"
              aria-label="linkedin">
              <LinkedinOutlined style={{ fontSize: '2rem', color: '#000' }} />
            </a>
            <a
              href={FACEBOOK_PAGE}
              target="_blank"
              rel="noreferrer"
              aria-label="facebook">
              <FacebookOutlined style={{ fontSize: '2rem', color: '#000' }} />
            </a>
            <a href={X_PAGE} target="_blank" rel="noreferrer" aria-label="x">
              <XOutlined style={{ fontSize: '2rem', color: '#000' }} />
            </a>
            <a
              href={INSTAGRAM_PAGE}
              target="_blank"
              rel="noreferrer"
              aria-label="instagram">
              <InstagramOutlined style={{ fontSize: '2rem', color: '#000' }} />
            </a>
          </Space>
        </Col>
      </Row>
      <Row
        justify="space-around"
        align="center"
        style={{ height: '3rem', padding: '0.5rem' }}>
        <Col span={18}>
          <Paragraph className="footer-text">
            COPYRIGHT &copy; EVELYNE MWAKINA 2024. All rights Reserved
          </Paragraph>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
