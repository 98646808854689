import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Typography,
  Row,
  Col,
  Button,
  Input,
  Card,
  Progress,
  Modal,
  Form,
  Select,
  Alert,
} from 'antd';
// import Reviews from '../../components/Reviews/Reviews';
import Comments from '../../components/Comments/Comments';
import { BOOK_LINK, sampleComments } from '../../data/data';

import './books.css';

const { Title } = Typography;
const { TextArea } = Input;
// Colors of the progress bar on the page
const twoColors = {
  '0%': '#108ee9',
  '100%': '#172d53',
};

// Books component
function Books() {
  const [comments, setComments] = useState(sampleComments);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // Review form logic
  const [form] = Form.useForm();

  // fetch the comments
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/comments`)
      .then(({ data }) => {
        // console.log('Comments recieved');
        setComments(data);
      })
      .catch((err) => {
        <Alert
          message="Sorry :("
          description="There was a problem loading comments from the server"
          type="error"
          closable
          showIcon
        />;
        console.error('There was an error retrieving comments: ', err);
      });
  }, []);

  // modal add review logic
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    // const values = form.getFieldValue();
    // TODO: Send these values to the db and handle asynchronicity
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      form.resetFields();
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <div className="overlay">
        <section id="books-landing" className="books-landing">
          <Row justify="start" align="middle">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} span={12}>
              <div className="books-landing-content">
                <Title level={1} className="books-landing-text">
                  BREAKING THE BARRIERS
                </Title>
                <Title level={3} className="books-landing-text">
                  EMPOWERED GIRL CHILD
                </Title>
                <br />
                <Button type="primary" size="large" shape="round">
                  <Link to={BOOK_LINK} target="_blank">
                    GET THE BOOK
                  </Link>
                </Button>
                <br />
                <br />
                <p>
                  Want to be notified about the book launch? Join the mailing
                  list
                </p>
                <br />
                {/* <Space direction="horizontal">
                  <Space.Compact>
                    <Input size="middle" />
                    <Button type="primary">SUBMIT EMAIL</Button>
                  </Space.Compact>
                </Space> */}
              </div>
            </Col>
          </Row>
        </section>
      </div>
      <Comments comments={comments} setComments={setComments} />
      <section className="books-review">
        {/* <Reviews /> */}
        <Row justify="space-around" align="middle" style={{ height: '10vh' }}>
          <Col span={12}>
            <Button type="primary" size="large" onClick={showModal}>
              SUBMIT A REVIEW
            </Button>
            <Modal
              title="Title"
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}>
              <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                size="large"
                onFinish={handleOk}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Input maxLength={25} />
                </Form.Item>
                <Form.Item
                  name="occupation"
                  label="Title"
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input
                    placeholder="Your Title for example Director of Company, Dr, Eng"
                    maxLength={25}
                  />
                </Form.Item>
                <Form.Item
                  name="title"
                  label="Book Title"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Select>
                    <Select.Option value="btb">
                      BREAKING THE BARRIERS
                    </Select.Option>
                    <Select.Option value="other">
                      PRESSURE MAKES DIAMONDS
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="review"
                  label="Review"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <TextArea
                    rows={4}
                    placeholder="Max Length is 120 words"
                    maxLength={1000}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
      </section>
      <section className="books-progress">
        <Row justify="space-around" align="middle">
          <Col span={18}>
            <Title level={3}>PRESSURE MAKES DIAMONDS</Title>
            <Card title="COMING SOON" hoverable bordered>
              <Title level={4}>Writing Progress</Title>
              <Progress percent={65} status="active" strokeColor={twoColors} />
              <Title level={4}>Editing Progress</Title>
              <Progress percent={0} status="active" strokeColor={twoColors} />
            </Card>
          </Col>
        </Row>
      </section>
      <section className="blog-advert">{/* <Reviews /> */}</section>
    </>
  );
}

export default Books;
