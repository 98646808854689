import React from 'react';
import { Row, Col, Typography, Carousel, Alert } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import './comments.css';

const { Title } = Typography;

function Comments({ comments }) {
  return (
    <section className="comments-section">
      {comments ? (
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} span={18}>
            <Carousel
              dots={false}
              arrows
              prevArrow={<LeftOutlined />}
              nextArrow={<RightOutlined />}
              autoplay
              autoplaySpeed={12000}>
              {comments.map((commentObj) => {
                const { comment, author, title } = commentObj;
                return (
                  <div key={`${author}`}>
                    <Row justify="center" align="middle">
                      <Col span={20}>
                        <p>{comment}</p>
                        <Title level={5}>
                          {author},
                          <br />
                          {title}
                        </Title>
                      </Col>
                    </Row>
                  </div>
                );
              }) ?? 'Loading Comments...'}
            </Carousel>
          </Col>
        </Row>
      ) : (
        <Alert
          message="Sorry :("
          description="There was a problem loading comments from the server"
          type="error"
          closable
          showIcon
        />
      )}
    </section>
  );
}

Comments.propTypes = {
  comments: propTypes.arrayOf(
    propTypes.objectOf(
      propTypes.shape({
        comment: propTypes.string.isRequired,
        author: propTypes.string.isRequired,
        title: propTypes.string.isRequired,
      }),
    ),
  ),
};

export default Comments;
