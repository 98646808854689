import rBgSection from '../assets/bg-about.jpg';
import lBgSection from '../assets/bg-lsection.jpg';

// BOOK LINK
export const BOOK_LINK =
  'https://nuriakenya.com/product/breaking-the-barriersempowered-girlchild-by-evelyne-mwakina/';

// SECTIONS DATA
export const rIMGURL = rBgSection;
export const rTitle = 'ABOUT THE BOOK';
export const rText = `Embark on a transformative journey through 'Breaking the Barriers: Empowered Girl Child.' Uncover moments where resilience, faith, and an abundance mindset collided to shape a narrative of triumph over adversity. \n Explore the depths of courage and perseverance as you navigate through the pages of empowerment, guided by Evelyne's remarkable life experiences.`;

export const lIMGURL = lBgSection;
export const lTitle = 'ABOUT THE AUTHOR';
export const lText = `Meet Evelyne Mshai Mwakina, the storyteller behind 'Breaking the Barriers: Empowered Girl Child.' Explore her personal narrative and the invaluable lessons learned, empowering girls to overcome challenges with unwavering determination, hard work, and profound trust in God. Discover the untold stories that inspire a new generation of resilient and empowered young women.`;

// SOCIAL MEDIA LINKS
export const LINKEDIN_PAGE =
  'https://www.linkedin.com/in/evelyne-mwakina-mprsk-64381034';
export const FACEBOOK_PAGE = 'https://www.facebook.com/evelyne.mwakina';
export const X_PAGE = 'https://x.com/evelynemwakina';
export const INSTAGRAM_PAGE = 'https://www.instagram.com/emwakina';

// Comments
export const sampleComments = [
  // {
  //   comment: `I have read your book and am amazed because of how powerful and deep it is. I want to read it again and again it is inspiring and challenging at the same time. I trust others will be blessed by this power filled book.`,
  //   author: 'Emma',
  //   title: 'Womens Ministry, CITAM Karen',
  // },
  // {
  //   comment: `Breaking the Barriers unfolds Evelyne Mwakina’s captivating
  //   journey. Early on, Evelyne formed a deep bond with our mother,
  //   shaped by a compassionate heart and a unique upbringing. Amidst
  //   the struggles of a large family, our lives were transformed by our
  //   mother’s spiritual journey in 1961, becoming a defining force
  //   for us all. Evelyne’s story is a demonstration of faith, overcoming
  //   struggles, and triumphantly succeeding in her journey of life. It
  //   proves that a faith-driven and family-nurtured Empowered Girl
  //   Child can shatter any barriers, offering motivation to all girls`,
  //   author: 'P.E. Mwaisaka',
  //   title: 'EBS, CBS, Elder Brother',
  // },
  // {
  //   comment: `Evelyne story is the epitome of an Empowered Girl Child,
  //   breaking free from societal constraints. She inspires us all to
  //   embrace our true selves. Her story reminds us that we should
  //   embrace our identities, shatter barriers and create a future where
  //   our dreams take flight.`,
  //   author: 'Hon. Hassan Osman',
  //   title: 'Director, Kenya Broadcasting Corporation',
  // },
  // {
  //   comment: `In summation, Evelyne Mwakina is a rare gem. She is a steadfast
  // pillar of strength to her friends and family. She embodies the
  // essence of breaking barriers and empowering the Girl Child.
  // May God’s abundant blessings be poured upon her`,
  //   author: 'Lydia W. Njoroge',
  //   title: 'Member, Flower Ministry, CITAM Karen',
  // },
];
