import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import propTypes from 'prop-types';
import axios from 'axios';

function EmailForm({ label }) {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ visible: false, message: '', type: '' });

  const onFinish = (email) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/mails`, email)
      .then((data) => {
        const statusCode = data.status;
        if (statusCode === 200 || statusCode === 201) {
          setAlert({
            visible: true,
            message: 'Successfully added email to mailing list',
            type: 'success',
          });
        }
      })
      .catch(() => {
        setAlert({
          visible: true,
          message: 'There was a problem submitting your email',
          type: 'error',
        });
      });
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCloseAlert = () => {
    setAlert({ ...alert, visible: false });
  };

  return (
    <section>
      <Form
        form={form}
        name="emailForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="inline">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
            {
              type: 'email',
              message: 'The input is not a valid email!',
            },
          ]}>
          <Input placeholder="Your email here" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {label}
          </Button>
        </Form.Item>
      </Form>

      {alert.visible && (
        <Alert
          message={alert.message}
          type={alert.type}
          closable
          showIcon
          onClose={onCloseAlert}
        />
      )}
    </section>
  );
}

EmailForm.propTypes = {
  label: propTypes.string,
};

export default EmailForm;
